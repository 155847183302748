<template>
  <Layout>
    <div class="tcf20container regcontainer">
      <div class="logo" />
      <img
        v-if="orientation !== 'landscape'"
        class="header"
        alt="Talking Tom and Friends"
        :src="require('../../assets/images/pp_friends.jpg')"
      >
      <h1
        class="title"
        :dir="dir"
        v-html="content.tcf20_title"
      />
      <div
        class="text noheader"
        :dir="dir"
      >
        <span>{{ content.tcf20_content_1 }}</span>
        <span>{{ numberOfVendors }}</span>
        <span>{{ content.tcf20_content_2 }}</span>
      </div>
      <Footer screen="tcf20" />
    </div>
  </Layout>
</template>

<script>
import Footer from '@components/tcf20/Footer.vue'

export default {
  metaInfo() {
    return {
      title: 'Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    Footer,
  },
  data() {
    return {
      content: {},
      orientation: '',
      dir: 'ltr',
      numberOfVendors: ''
    }
  },
  created() {
    this.$compliance.initCollector('consent_tcf20')
    this.content = this.$compliance.getLocalisedContent()
  },
  mounted() {
    this.$compliance.TCF20.setCurrentConsentScreen('main')
    this.orientation = this.$compliance.screenOrientation
    this.$compliance.forceRedraw()
    this.dir = this.$compliance.dir
    this.$compliance.GVL.getVendorsSize().then((size) => {
      this.numberOfVendors = size
    })
  },
}
</script>

<style lang="scss">
.tcf20container {
  position: relative;
  padding: 10px 0 10px 0;
  background: #fff;
  width: 100%;

  .logo {
    width: 20%;
    height: 40px;
    max-width: 130px;
    margin: 20px auto 0 auto;
    background-image: url('../../assets/images/logo.jpg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .header {
    width: calc(100% - 60px);
    height: auto;
    margin: 0 30px;
  }
  .v-lazy-image {
    filter: blur(10px);
    transition: filter 0.7s;
  }
  .v-lazy-image-loaded {
    filter: blur(0);
  }
  .title {
    text-transform: uppercase;
    color: #4f5c68;
    font-size: 1.8em;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 10px auto 10px auto;

    @media screen and (min-width: 768px) {
      font-size: 3.2em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .text {
    padding: 0 10px 130px 10px;
    font-size: 1em;
    color: #4c5d6a;
    text-align: center;
    &.noheader {
      margin: 0;
    }
    span.underlined {
      color: #688194;
      text-decoration: underline;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8em;
      padding: 0 10px 190px 10px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.1em;
      padding: 0 10px 200px 10px;
    }
  }
}
.absolutefooter .tcf20container {
  height: 100%;
}
.ru .tcf20container .title {
  font-size: 1.6em;
}
.ja .tcf20container .title {
  font-size: 1.4em;
  @media screen and (min-width: 520px) {
    font-size: 1.9em;
  }
  @media screen and (min-width: 768px) {
    font-size: 3.2em;
  }
  @media screen and (min-width: 1024px) {
    font-size: 4em;
  }
}
</style>
